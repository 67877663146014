import "core-js/modules/es.date.now";import "core-js/modules/es.date.to-string";import "core-js/modules/es.object.to-string";import _slicedToArray from "/Users/learning/Documents/workspace/kindstar/customer-orders/node_modules/@babel/runtime/helpers/esm/slicedToArray"; //
//
//
//
//
//
//
//
//
//
//
//
//
//

import { saveAs } from 'file-saver'; // https://github.com/eligrey/FileSaver.js
import { parseTime } from '../../lib/modules/date';
export default {
  components: {},
  props: {
    url: {
      type: String,
      "default": undefined },

    fileType: {
      type: String,
      "default": 'xlsx' },

    fileName: {
      type: String },

    params: {
      "default": function _default() {return {};} } },


  methods: {
    download: function download(_ref) {var _this = this;var url = _ref.url,params = _ref.params,fileName = _ref.fileName,fileType = _ref.fileType;
      var _url = url || this.url;
      var _params = params || this.params;
      var _fileName = fileName || this.fileName;
      var _fileType = fileType || this.fileType;

      if (_url) {
        return new Promise(function (resolve) {
          _this.$Api.getblob(_url, { params: _params }).then(function (_ref2) {var _ref3 = _slicedToArray(_ref2, 2),err = _ref3[0],res = _ref3[1];
            if (!err && res) {
              var file = new File(
              [res],
              (_fileName || "\u6587\u4EF6".concat(Date.now())) +
              '-' +
              parseTime(0, '{y}-{m}-{d}') + ".".concat(
              _fileType),
              {
                type: 'text/plain;charset=utf-8' });



              saveAs(file);
            }
            console.log(err, res);
            resolve([err, res]);
          });
        });
      }
    } } };